import React, {useEffect, useState} from 'react';
import ReportTopStaffApi from "../../../../api/report/ReportTopStaff";

const TopStaff = ({selectedChannel, selectedTime}) => {
    const [topStaff, setTopStaff] = useState([]);

    useEffect(() => {
        let isMounted = true;

        const start_date = selectedTime[0];
        const end_date = selectedTime[1];

        ReportTopStaffApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;

            if(isMounted && data){
                setTopStaff(data);
            }
        })
        .catch(err => console.log(err));

        // Hàm cleanup để hủy bỏ tác vụ bất đồng bộ khi component unmount
        return () => {
            isMounted = false; // Đặt biến isMounted thành false khi component unmount
        };

    }, [selectedTime, selectedChannel]);

    const getAvatar = (staff) => {
        let staffName = staff.name;
        if (staff.avatar){
            return <img src={staff.avatar} alt="" width="64" height="64" className="staff-overview-avatar customer-avatar-undefined"/>
        }
        else{
            return <div className="avatar-name"><div className='img'>{staffName.substr(0,1).toUpperCase()}</div></div>;
        }
    }

    return (
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Nhân viên</div>
                    <div className="desc">Top nhân viên có tương tác nhiều nhất với khách hàng</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content">
            {topStaff && topStaff.length > 0 && (
                <div className='staff-overview-content'>
                    {topStaff.map((staff, index) => staff.name && (<div key={staff._id} className="staff-item">
                        <img src="https://pancake.vn/static/images/statistic/overview/staff-gold-medal.svg" alt="medal"/>
                        <div className="avatar-and-rank">
                            {getAvatar(staff)}
                            <div className="rank">{index + 1}</div>
                        </div>
                        <div className="detail">
                            <div className="name">{staff.name}</div>
                            <div className="engagement">
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256" className="icon">
                                    <path d="M169.57,72.59A80,80,0,0,0,16,104v66a14,14,0,0,0,14,14H86.67A80.15,80.15,0,0,0,160,232h66a14,14,0,0,0,14-14V152A80,80,0,0,0,169.57,72.59ZM32,104a64,64,0,1,1,64,64H32ZM224,216H160a64.14,64.14,0,0,1-55.68-32.43A79.93,79.93,0,0,0,174.7,89.71,64,64,0,0,1,224,152Z"></path>
                                </svg>
                                <div className="count">{staff.count} lượt</div>
                            </div>
                        </div>
                    </div>))}
                </div>
            )}

            </div>
        </div>
    );
};

export default TopStaff;
