import axios from "axios";
import Config from "../config";

const linkCustomer = (data) => {
    let {mapping_id, user_id, social_id, customer_id, type_customer = 'customer', type_social = 'zalo'} = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/customer/link",
        data: {mapping_id, user_id, social_id, customer_id, type_customer, type_social }
    });
};

export default linkCustomer;
