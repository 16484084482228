import axios from "axios";
import Config from "../config";

const getQuotaMessage = (data) => {
    const { user_social_id, social_channel_id } = data;
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/zalo/get-quota",
        data: {user_social_id, social_channel_id}
    });
};

export default getQuotaMessage;
