import React, {useRef, useEffect, useState, useContext} from 'react';
import {useGlobal} from "reactn";
import "./Panel.sass";
import TopBar from "./components/TopBar";
import SearchBar from "./components/SearchBar";
import SelectChannel from "./components/SelectChannel";
import NavBar from "./components/NavBar";
import MeetingBar from "./components/MeetingBar";
import Room from "./components/Room";
import User from "./components/User";
import getRooms from "../../actions/getRooms";
import ClipLoader from "react-spinners/ClipLoader";
import {useDispatch, useSelector} from "react-redux";
import Actions from "../../constants/Actions";
import {useLocation} from "react-router-dom";
import getNotificationUser from "../../actions/getNotificationUser";
import { AppContext } from "../../context/AppContext";
import store from "../../store";

const Panel = () => {
    const nav = useGlobal('nav')[0];
    const socialActive = useGlobal('socialActive')[0];
    const activeChannel = useGlobal('activeChannel')[0];
    const activeMessageStatus = useGlobal('activeMessageStatus')[0];
    const activeTags = useGlobal('activeTags')[0];
    const searchText = useGlobal('search')[0];
    const rooms = useSelector(state => state.io.rooms);
    const [loading, setLoading] = useState(true);
    const { i18n } = useContext(AppContext);


    const dispatch = useDispatch();
    const location = useLocation();

    const pageCurrentRoom = useSelector(state => state.io.room_page_current);
    const [disableLoadMore, setDisableLoadMore] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const chat = useRef(null);

    useEffect(() => {
        getNotificationUser().then(res => {
            window.parent.postMessage({
                func: 'pushNotification',
                message: res?.data?.total || 0
            }, "*");

            dispatch({type: Actions.SET_USER_NOTIFICATION, notificationsUser: res.data});
        }).catch(err => console.log(err));
    }, []);

    useEffect(() => {
        setLoading(true);
        getRooms(searchText, socialActive, activeChannel, activeMessageStatus, activeTags).then(res => {
            setLoading(false);
            dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms});
        }).catch(err => console.log(err));
    }, [socialActive, activeChannel, activeMessageStatus])

    const roomsList = rooms.map(room => <Room key={room._id} room={room} />);
    // const searchResultsList = searchResults.map(user => <User key={user._id} user={user} />);

    const Loading = () => (
        <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column loading-block">
            <ClipLoader
                size={20}
                color={"#0067ac"}
                loading={loading}
            />
        </div>
    );

    const Notice = ({text}) => <div className="notice">{text}</div>

    const removeDuplicates = (arr) => {
        const seen = {};
        return arr.filter((item) => {
            return seen.hasOwnProperty(item._id) ? false : (seen[item._id] = true);
        });
    }

    const loadMoreMessages = () => {
        let nextPage = pageCurrentRoom + 1;
        getRooms(searchText, socialActive, activeChannel, activeMessageStatus, activeTags, nextPage)
            .then(res => {
                let dataResponse = res.data.rooms;
                if(dataResponse && dataResponse.length > 0){
                    dispatch({type: Actions.SET_CURRENT_PAGE, room_page_current: nextPage});
                    let result = [...rooms, ...dataResponse];
                    const filterResult = removeDuplicates(result);
                    store.dispatch({type: Actions.SET_ROOMS, rooms: filterResult})
                }
                else{
                    setDisableLoadMore(true);
                }
                setLoadingMore(false);
            })
            .catch(err => {
                setLoadingMore(false);
            });
    }

    const handleScroll = () => {
        if(loadingMore || disableLoadMore) return;
        const scrollHeight = chat.current.scrollHeight;
        const currentHeight = Math.ceil(
            chat.current.scrollTop + window.innerHeight
        );

        if (currentHeight + 1 >= scrollHeight) {
            setLoadingMore(true);
            loadMoreMessages();
        }
    };

    return (
        <div className="panel">
            <TopBar setDisableLoadMore={setDisableLoadMore}/>
            <SearchBar loading={loading} setLoading={setLoading}/>
            <SelectChannel/>
            {/* <NavBar/> */}
            {loading && <div className="rooms"><Loading/></div>}
            {!loading && <div className="rooms" ref={chat} onScroll={handleScroll} >
                {nav === 'rooms' && roomsList}
                {nav === 'rooms' && rooms.length === 0 && <Notice text="There are no rooms yet. Contact someone or create a group to begin!" />}
                {loadingMore && (<div className="text-center mt-2">{i18n.t('loading')}...</div>)}
            </div>}
        </div>
    );
}

export default Panel;
