import axios from "axios";
import setAuthToken from "./setAuthToken";
import Config from "../config";

const detectCustomer = (data) => {
    let {user_id} = data;

    setAuthToken(Config.epointsToken);

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/customer/detect",
        data: {user_id }
    });
};

export default detectCustomer;
