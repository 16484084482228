import axios from "axios";
import Config from "../config";

const sendMessageTransaction = (data) => {
    const { channel_id, user_social_id, type } = data;
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/zalo/send-transaction",
        data: {channel_id, user_social_id, type}
    });
};

export default sendMessageTransaction;
