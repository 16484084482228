import axios from "axios";
import Config from "../config";

const getListTagByUser = (data) => {
    const { user_id } = data;
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/tags/list-by-user",
        data: {user_id}
    });
};

export default getListTagByUser;
