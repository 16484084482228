import axios from "axios";
import setAuthToken from "../actions/setAuthToken";
import Config from "../config";

const searchProduct = (data) => {
    const {keyword, page = 1} = data;
    
    setAuthToken(Config.epointsToken);

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/products/lists",
        data: {keyword, page}
    });
};

export default searchProduct;
