import axios from "axios";
import Config from "../config";

const blockUser = data => {
    const { userId, isBlocked } = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/user/block",
        data: { userId, isBlocked }
    });
};

export default blockUser;
