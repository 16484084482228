import axios from "axios";
import Config from "../config";

const syncMessage = (channel_id) => {

    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/config/sync-message",
        data: {channel_id}
    });
};

export default syncMessage;
