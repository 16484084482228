import { createContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Loading from "../components/Loading";
import { useTranslation } from 'react-i18next';
import "./AppContext.sass";

export const AppContext = createContext({});

export const AppProvider = ({children}) => {
    const { i18n } = useTranslation();
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const showAlertAction = (type = 'success',message, title = '') => {
        setShowAlert(<SweetAlert 
            type={type}
            title={title} 
            btnSize="sm" 
            confirmBtnText={i18n.t('confirm')} 
            onConfirm={() => setShowAlert(false)} 
            onCancel={() => setShowAlert(false)}>
                {message}
            </SweetAlert>
        )
    }

    const showConfirmAction = (callback, message, title = '') => {
        setShowAlert(<SweetAlert 
            warning
            showCancel
            confirmBtnText={i18n.t('confirm')} 
            cancelBtnText={i18n.t('cancel')} 
            confirmBtnBsStyle="danger"
            title={title}
            btnSize="sm" 
            onConfirm={() => callback()} 
            onCancel={() => setShowAlert(false)}>
                {message}
            </SweetAlert>
        )
    }

    return (
        <AppContext.Provider value={{ 
                showAlertAction, 
                showConfirmAction, 
                isLoading, 
                i18n,
                setIsLoading,
            }}
        >
            {children}
            {showAlert}
            {isLoading && <Loading loading={isLoading}/>}
        </AppContext.Provider>
    )
}