import axios from "axios";
import Config from "../config";

const updateJourney = (data) => {
    const {customer_lead_id, pipeline_id, journey_old, journey_new} = data;
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/customer/update-journey",
        data: {customer_lead_id, pipeline_id, journey_old, journey_new}
    });
};

export default updateJourney;
