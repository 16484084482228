const Actions = {
    IO_INIT: 'IO_INIT',
    SET_MESSAGES: 'SET_MESSAGES',
    MORE_MESSAGES: 'MORE_MESSAGES',
    MESSAGE: 'MESSAGE',
    SET_WAIT_MESSAGE: 'SET_WAIT_MESSAGE',
    UPDATE_MESSAGE: 'UPDATE_MESSAGE',
    SET_ROOM: 'SET_ROOM',
    SET_CUSTOMER: 'SET_CUSTOMER',
    SET_ROOMS: 'SET_ROOMS',
    PREPEND_ROOMS: 'PREPEND_ROOMS',
    SET_CUSTOMERS: 'SET_CUSTOMERS',
    SET_CHANNELS: 'SET_CHANNELS',
    SET_ACTIVE_CHANNEL: 'SET_ACTIVE_CHANNEL',
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_ACTIVE_SOCIAL: 'SET_ACTIVE_SOCIAL',
    SET_ACTIVE_MESSAGE_STATUS: 'SET_ACTIVE_MESSAGE_STATUS',
    SET_ACTIVE_TAG: 'SET_ACTIVE_TAG',
    SET_USER_CHECKED_TAG: 'SET_USER_CHECKED_TAG',
    ONLINE_USERS: 'ONLINE_USERS',

    SET_EMOJI: 'SET_EMOJIS',
    SET_TYPING: 'SET_TYPING',

    RTC_PRODUCER: 'RTC_PRODUCER',
    RTC_PRODUCERS: 'RTC_PRODUCERS',
    RTC_RESET_PRODUCERS: 'RTC_RESET_PRODUCERS',
    RTC_LEAVE: 'RTC_LEAVE',
    RTC_ROOM_ID: 'RTC_ROOM_ID',
    RTC_CONSUMERS: 'RTC_CONSUMERS',
    RTC_NEW_PEER: 'RTC_NEW_PEER',
    RTC_CALL: 'RTC_CALL',
    RTC_CLOSE: 'RTC_CLOSE',
    RTC_ANSWER: 'RTC_ANSWER',
    RTC_SET_COUNTERPART: 'RTC_SET_COUNTERPART',

    NOTIFICATION: 'NOTIFICATION',
    SET_USER_NOTIFICATION: 'SET_USER_NOTIFICATION',

    MESSAGES_ADD_ROOM_UNREAD: 'MESSAGES_ADD_ROOM_UNREAD',
    MESSAGES_REMOVE_ROOM_UNREAD: 'MESSAGES_REMOVE_ROOM_UNREAD',

    REFRESH_MEETINGS: 'REFRESH_MEETINGS',
};

export default Actions;
