import React, {useContext} from 'react';
import "./SearchBar.sass";
import {FiSearch} from 'react-icons/fi';
import {useGlobal} from "reactn";
import searchCustomer from "../../../actions/searchCustomer";
import { AppContext } from '../../../context/AppContext';

const SearchBar = ({setSearchResultCustomer, setSearchPage, showAlertAction}) => {
    const [keywordCustomer, setKeywordCustomer] = useGlobal('keywordCustomer');
    const { i18n } = useContext(AppContext);
    
    const onSubmit = () => {
        loadCustomer();
    }

    const onKeydown = (e) => {
        if (e.keyCode === 13) {
            loadCustomer();
        }
    }

    const loadCustomer = () => {
        if(keywordCustomer === ''){
            showAlertAction('warning' , i18n.t('not_enter_keyword'));
            return;
        }

        setSearchPage('customer');
        
        searchCustomer({keyword: keywordCustomer}).then(res => {
            if(res.data.error === 0){
                setSearchResultCustomer(res.data.data);
            }
            else{
                setSearchResultCustomer([]);
                setKeywordCustomer('');
            }
        }).catch(err => console.log(err));
    };

    return (
        <div className="search-bar uk-flex uk-flex-center uk-flex-middle">
            <div className="icon" onClick={() => onSubmit()}>
                <FiSearch/>
            </div>
            <div className="uk-inline search">
                <input className="uk-input uk-border-pill" placeholder={i18n.t('enter_phone_name_email')}
                    value={keywordCustomer} 
                    onKeyDown={(e) => onKeydown(e)}
                    onChange={(e) => {setKeywordCustomer(e.target.value)}} />
            </div>
            <button className="btn btn-primary" onClick={() => onSubmit()}>{i18n.t('search')}</button>
        </div>
    );
}

export default SearchBar;
