import React, {useEffect, useState, useContext} from 'react';
import getChannels from "../../../actions/getChannels";
import './ReportModal.sass';
import { AppContext } from "../../../context/AppContext";
import RangePickerCustom from "../../../components/RangePickerCustom";
import { Modal, Card, Button, Select, Space, Col, Row, Statistic } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';

const ReportModal = ({showModalReport, setShowModalReport}) => {
    const [selectedChannel, setSelectedChannel] = useState("");
    const [selectedSource, setSelectedSource] = useState("");
    const [channelLists, setChannelLists] = useState("");
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const { i18n } = useContext(AppContext);

    useEffect(() => {
        getChannels().then(res => {
            let channels = res.data.channels;
            let channelLists = channels.map(channel => ({value: channel._id, label: channel.nameApp}));
            channelLists.unshift({value: '', label: 'Tất cả'})
            setChannelLists(channelLists);
        })
        .catch(err => console.log(err));
    }, []);

    const handleChangeStatus = (selected) => {
        setSelectedChannel(selected);
    };

    const handleChangeSource = (selected) => {
        setSelectedSource(selected);
    };

    const handleCancelModal = () => {
        setShowModalReport(false);
    };

    return (
        <Modal width={1300} title={i18n.t('report')} open={showModalReport} onCancel={handleCancelModal}>
            <Space direction="vertical" size={24} style={{width: "100%"}}>
            <Card>
            <Row gutter={24} className="mb-2">
                <Col span={6}>
                    <span>{i18n.t('choose_source')}</span>
                </Col>
                <Col span={7}>
                    <span>{i18n.t('choose_channel')}</span>
                </Col>
                <Col span={6}>
                    <span>{i18n.t('time')}</span>
                </Col>
                <Col span={5}></Col>
            </Row>
            <Row gutter={24}>
                <Col span={6}>
                    <div className="">
                        <Select
                            style={{ width: "100%" }}
                            value={selectedSource}
                            onChange={handleChangeSource}
                            optionFilterProp="children"
                            placeholder={`${i18n.t('choose_source')}...`}
                            options={[
                                {value: '', label: 'Tất cả'},
                                {value: 'zalo', label: 'Zalo'},
                                {value: 'facebook', label: 'Facebook'},
                            ]}
                        />
                    </div>
                </Col>
                <Col span={7}>
                    <div className="">
                        <Select showSearch
                            style={{ width: "100%" }}
                            value={selectedChannel}
                            onChange={handleChangeStatus}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            placeholder={`${i18n.t('choose_channel')}...`}
                            options={channelLists}
                        />
                    </div>
                </Col>
                <Col span={6}>
                    <div className="choose-time-report ">
                        <Space direction="vertical" size={12}>
                            <RangePickerCustom selectedDateRange={selectedDateRange} setSelectedDateRange={setSelectedDateRange}/>
                        </Space>
                    </div>
                </Col>
                <Col span={4}>
                    <Button type="primary">{i18n.t('view_report')}</Button>
                </Col>
            </Row>
            </Card>
            <Row gutter={16}>
                <Col span={24}>
                    <Card title="Số lượng hội thoại">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Card>
                                <Statistic
                                    title="Tổng số hội thoại"
                                    value={11.28}
                                    precision={2}
                                    valueStyle={{color: '#3f8600'}}
                                    prefix={<ArrowUpOutlined />}
                                    suffix="%"
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                <Statistic
                                    title="Người dùng mới"
                                    value={9.3}
                                    precision={2}
                                    valueStyle={{ color: '#cf1322' }}
                                    prefix={<ArrowDownOutlined />}
                                    suffix="%"
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                <Statistic
                                    title="Khách hàng đã liên kết"
                                    value={120}
                                    valueStyle={{ color: '#3f8600' }}
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card>
                                <Statistic
                                    title="Khách hàng chưa liên kết"
                                    value={65}
                                    valueStyle={{ color: '#cf1322' }}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            </Space>
        </Modal>
    );
}

export default ReportModal;
