import Actions from "../constants/Actions";

const initialState = {
    io: null,
    room: null,
    messages: [],
    rooms: [],
    channels: [],
    customer: {},
    active_channel: null, // Channel đang active
    room_page_current: 1,
    active_social: '', // social đang active
    active_message_status: null, // Trạng thái tin nhắn đang active (chưa đọc, đã đọc,..)
    active_tags: [], // Trạng thái tag đang chọn
    notifications: [],
    id: null,
    onlineUsers: [],
    refreshMeetings: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.IO_INIT:
            return {
                ...state,
                io: action.io,
                id: action.io.id,
            };
        case Actions.SET_ROOMS:
            return {
                ...state,
                rooms: action.rooms
            };
        case Actions.PREPEND_ROOMS:
            let listRooms = state.rooms;
            const filterRooms = listRooms.filter(item => item._id !== action.room._id);
            return {
                ...state,
                rooms: [action.room, ...filterRooms]
            };
        case Actions.SET_CHANNELS:
            return {
                ...state,
                channels: action.channels
            };
        case Actions.SET_ACTIVE_CHANNEL:
            return {
                ...state,
                active_channel: action.active_channel
            };

        case Actions.SET_CURRENT_PAGE:
            return {
                ...state,
                room_page_current: action.room_page_current
            };
        case Actions.SET_ACTIVE_SOCIAL:
            return {
                ...state,
                active_social: action.active_social
            };
        case Actions.SET_ACTIVE_TAG:
            return {
                ...state,
                active_tags: action.active_tags
            };
        case Actions.SET_ACTIVE_MESSAGE_STATUS:
            return {
                ...state,
                active_message_status: action.active_message_status
            };
        case Actions.SET_ROOM:
            return {
                ...state,
                room: action.room,
            };
        case Actions.SET_CUSTOMER:
            return {
                ...state,
                customer: action.customer,
            };
        case Actions.SET_MESSAGES:
            return {
                ...state,
                messages: action.messages,
            };
        case Actions.MORE_MESSAGES:
            return {
                ...state,
                messages: [...action.messages, ...state.messages],
            }
        case Actions.SET_WAIT_MESSAGE:
            state.messages.pop();
            return {
                ...state,
                messages: [...state.messages, action.message],
            };
        case Actions.MESSAGE:
            return {
                ...state,
                messages: [...state.messages, action.message],
            };
        case Actions.ONLINE_USERS:
            return {
                ...state,
                onlineUsers: action.data,
            };
        case Actions.REFRESH_MEETINGS:
            return {
                ...state,
                refreshMeetings: action.timestamp,
            };
        case Actions.NOTIFICATION:
            return {
                ...state,
                notifications: action.notifications
            };
        case Actions.SET_USER_NOTIFICATION:
            return {
                ...state,
                notificationsUser: action.notificationsUser
            };
        default:
            return state;
    }
};

export default reducer;
