import React, {useEffect, useState} from 'react';
import { Table, Row, Col } from 'antd';
import PieChart from "../Chart/PieChart";
import TopStaff from "../Block/TopStaff";
import ReportDetailStaffApi from "../../../../api/report/ReportDetailStaff";
import ReportRatioStaffApi from "../../../../api/report/ReportRatioStaff";

const StaffReport = ({selectedChannel, selectedTime}) => {
    const [dataPieChart, setDataPieChart] = useState();
    const [detailStaff, setDetailStaff] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingDetail, setLoadingDetail] = useState(true);

    useEffect(() => {
        const start_date = selectedTime[0];
        const end_date = selectedTime[1];
        ReportRatioStaffApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            getDataOverViewLineChart(data);
            setLoading(false);
        })
        .catch(err => console.log(err));


        ReportDetailStaffApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setDetailStaff(data);
            setLoadingDetail(false);
        })
        .catch(err => console.log(err));

        
    }, []);

    const optionsPie = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        // let data = context[0].formattedValue;
                        return `Tỉ lệ phản hồi`;
                    },
                    label: function(context) {
                        let label = context.parsed || '';
                        return `${label}%`;
                    }
                }
            }
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        maintainAspectRatio: false,
        aspectRatio: 1
    };

    const getDataOverViewLineChart = (data) => {
        const result = {
            labels: data.labels,
            datasets: [
                {
                    label: '# of Votes',
                    data: data.values,
                    backgroundColor: [
                        'rgb(38,115,255)',
                        'rgb(100,110,144)',
                        'rgb(25,172,93)',
                        'rgb(155,68,186)',
                        'rgb(81,191,255)',
                        'rgb(189,39,39)',
                    ],
                    borderColor: [
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                        '#fff',
                    ],
                    borderWidth: 1,
                },
            ],
        };

        setDataPieChart(result);
    }

    const ReportDetailTable = () => {
        if(loadingDetail) return;

        const fixedColumns = [
            {
                title: 'Nhân viên',
                dataIndex: 'staff_name',
                fixed: true,
            },
            {
                title: 'Tổng tin nhắn',
                dataIndex: 'message_count',
            },
        ];

        let fixedData = [];
        let totalMessage = 0;
        for (const index in detailStaff) {
            fixedData.push({
                key: parseInt(index),
                staff_name: detailStaff[index].staff_name,
                message_count: detailStaff[index].message_count,
            });

            totalMessage += detailStaff[index].message_count;
        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
                summary={() => (
                    <Table.Summary fixed>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}><strong>Tổng</strong></Table.Summary.Cell>
                        <Table.Summary.Cell index={1}><strong>{totalMessage}</strong><br/> tin nhắn</Table.Summary.Cell>
                    </Table.Summary.Row>
                    </Table.Summary>
                )}
            />
        )
    }

    return (
        <>
        <div className='activity-overview'>
            <Row gutter={24} className="mb-2">
                <Col span={12}>
                    <div className="card">
                        <div className="header">
                            <div className="title-desc">
                                <div className="title">Tỉ lệ phản hồi theo nhân viên</div>
                                <div className="desc">Tỉ lệ phản hồi của nhân viên trong khoảng thời gian</div>
                            </div>
                            <div className='interaction'></div>
                        </div>
                        <div className="content">
                            <div className="chart-container">
                                {!loading && <PieChart options={optionsPie} data={dataPieChart}/>}
                            </div>
                        </div>
                    </div>  
                </Col>
                <Col span={12}>
                    <TopStaff selectedChannel={selectedChannel} selectedTime={selectedTime}/>
                </Col>
            </Row>
        </div>
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Thống kê chi tiết</div>
                    <div className="desc">Thống kê chi tiết tương tác của nhân viên với khách</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content">
                <div className="chart-container">
                    {!loadingDetail && <ReportDetailTable/>}
                </div>
            </div>
        </div>
        </>
    );
};

export default StaffReport;
