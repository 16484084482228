import React, {useEffect, useState, useRef} from 'react';
import "./SearchChannel.sass";
import {useGlobal} from "reactn";
import Actions from "../../../constants/Actions";
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import {useDispatch, useSelector} from "react-redux";
import getChannels from "../../../actions/getChannels";
import icons from "../../../assets";
import { useTranslation } from 'react-i18next';
import i18n from './../../../translate';

const SelectChannel = () => {
    const { t, i18n } = useTranslation();
    const channels = useSelector(state => state.io.channels);
    const socialActive = useGlobal('socialActive')[0];
    const setActiveChannel = useGlobal('activeChannel')[1];
    const setPageCurrentRoom = useGlobal('pageCurrentRoom')[1];
    const setActiveMessageStatus = useGlobal('activeMessageStatus')[1];
    const dispatch = useDispatch();
    const [selectedChannel, setSelectedChannel] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");

    useEffect(() => {
        getChannels(socialActive).then(res => dispatch({type: Actions.SET_CHANNELS, channels: res.data.channels})).catch(err => console.log(err));
    }, [socialActive]);

    let channelLists = channels.map(channel => ({value: channel._id, label: channel.nameApp, image: channel.socialChanelId, source: channel.source}));

    channelLists.unshift({value: "", label: t('all')});

    const customOption = ({ innerProps, label, data }) => (
        <div {...innerProps} 
            style={{
                display: 'flex',
                alignItems: 'center',
                padding: '8px',
                transition: 'background-color 0.3s',
                borderBottom: '1px dashed #cacaca',
                cursor: 'pointer'
            }}
        >
            <img 
                src={data.source === 'facebook' ? `https://graph.facebook.com/v14.0/${data.image}/picture` : (data.source === 'zalo' ? icons.Zalo : icons.Home)} 
                alt="" 
                style={{ marginLeft: '8px', marginRight: '8px', marginTop: '5px', width: '24px', height: '24px' }} 
            />
            {label}
        </div>
    );

    const handleChangeChannel = (selected) => {
        const channelId = selected.value;
        setSelectedChannel(selected);
        setActiveChannel(channelId);
        dispatch({type: Actions.SET_ACTIVE_CHANNEL, active_channel: channelId});
        setPageCurrentRoom(1);
    };

    const statusLists = [
        {value: '', label: t('all')},
        {value: 'not_seen', label: t('unseen')},
        {value: 'seen', label: t('seen')},
        {value: 'replied', label: t('replied')}
    ];

    const handleChangeStatus = (selected) => {
        const statusId = selected.value;
        setSelectedStatus(selected);
        dispatch({type: Actions.SET_ACTIVE_MESSAGE_STATUS, active_message_status: statusId})
        setActiveMessageStatus(statusId)
    };

    return (
        <div className="select-wrap uk-flex select-bar uk-flex-between">
            <div className="select-channel">
                <Select
                    value={selectedChannel}
                    onChange={handleChangeChannel}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={channelLists[0]}
                    isSearchable="true"
                    name="color"
                    placeholder={`${t('choose_channel')}...`}
                    options={channelLists}
                    components={{
                        Option: customOption,
                    }}
                    getOptionLabel={(option) => option.label}
                />
            </div>
            <div className="select-status">
                <Select
                    value={selectedStatus}
                    onChange={handleChangeStatus}
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={statusLists[0]}
                    isSearchable="true"
                    placeholder={`${t('choose')}...`}
                    options={statusLists}
                />
            </div>
        </div>
    );
}

export default SelectChannel;
