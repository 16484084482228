import axios from "axios";
import Config from "../config";

const createTag = (data) => {
    const {user_id, name, color} = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/tags/create",
        data: {user_id, name, color}
    });
};

export default createTag;
