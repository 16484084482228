import React, {useContext} from 'react';
import "./Room.sass";
import {Image} from "react-bootstrap";
import './Product.sass';
import MasterConstant from "../../../constants/MasterConstant";
import { AppContext } from "../../../context/AppContext";

const Product = ({product}) => {
    const { i18n } = useContext(AppContext);

    return (
        <div className="product-item">
            <div className="left">
                <Image width="120" src={product.avatar}/>
            </div>
            <div className="right">
                <p className="product-name">{product.product_name}</p>
                <p>{i18n.t('unit')}: {product.unit_name}</p>
                <p>{i18n.t('price')}: {MasterConstant.formatPrice(product.old_price || product.new_price)} đ</p>
                <p>{i18n.t('promotion_price')}: {MasterConstant.formatPrice(product.new_price)} đ</p>
                <p className={`btn-detail-product text-right`}>
                    <a href={`${MasterConstant.getParentOrigin()}/admin/product-child/detail/${product.product_id}`} target="_blank" rel="noreferrer">{i18n.t('more_detail')}</a>
                </p>
            </div>
        </div>
    );
}

export default Product;
