import axios from "axios";
import Config from "../config";

const createNote = (data) => {
    const { room_id, content } = data;
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/notes/create",
        data: {room_id, content}
    });
};

export default createNote;
