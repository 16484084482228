import { setGlobal } from 'reactn';
import setAuthToken from "./actions/setAuthToken";
import initIO from "./actions/initIO";
import store from "./store";
import jwtDecode from 'jwt-decode';
import axios from "axios";
import Config from "./config";
import { Cookies } from "react-cookie";

const init = async () => {
    document.addEventListener('gesturestart', e => {
        e.preventDefault();
    });

    if (localStorage.getItem('app') !== 'Clover 2.x.x') {
        localStorage.clear();
        localStorage.setItem('app', 'Clover 2.x.x');
    }
    const cookies = new Cookies();
    let eToken = cookies.get('EPOINTS_SSO');
    // eToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vc3RhZmYtYXBpL3VzZXIvcmVmcmVzaC10b2tlbiIsImlhdCI6MTcxMTY3Njc1NywiZXhwIjoxNzExNzAwMzkyLCJuYmYiOjE3MTE2Nzg3OTIsImp0aSI6InBnZ1NoNkRJZDRWNDJIaGUiLCJzdWIiOjE1LCJwcnYiOiJhMGYzZTc0YmVkZjUxMmM0Nzc4Mjk3ZGU1ZjkyMDg2ZGFkMzljYTlmIiwic2lkIjoidGFtIiwiYnJhbmRfY29kZSI6InNhbGUifQ.UZV2uXdmk2XhTJLfwac7ZTLV1r_uYXKNkBQbeNmj1JE";
    Config.epointsToken = eToken;

    const decoded = jwtDecode(eToken, {complete: true});
    let brand = decoded?.brand_code ?? decoded?.brand;
    let usernamePortal = decoded?.sub;
    localStorage.setItem('uid', usernamePortal);
    cookies.set('BRAND', brand);

    let token = localStorage.getItem('token');

    if(token && eToken){
        const eDecoded = jwtDecode(eToken, {complete: true});
        const decoded = jwtDecode(token, {complete: true});

        if(eDecoded.sid !== decoded.sid){
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            token = null;
        }
    }

    if(!token){
        token = eToken;
    }
    let userString = localStorage.getItem('user');
    let user = userString ? JSON.parse(userString) : null;
    if (token) {
        const dateNow = new Date();
        let isExpired = (decoded.exp * 1000 < dateNow.getTime());
        let result;

        if (!isExpired) {
            try {
                const res = await axios({
                    method: "post",
                    url: (Config.url || '') + "/api/check-user-token",
                    data: {token: token},
                    headers: {
                        'brand-code': brand || 'qc'
                    }
                });
                result = res.data;
            } catch (e) {
                console.log(e)
                result = null;
            }
        }

        if (!result || result.error) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            token = localStorage.getItem('token');
            userString = localStorage.getItem('user');
            user = userString ? JSON.parse(userString) : null;
        } else {
            localStorage.setItem('token', result.token);
            localStorage.setItem('user', JSON.stringify(jwtDecode(result.token)));
            token = localStorage.getItem('token');
            userString = localStorage.getItem('user');
            user = userString ? JSON.parse(userString) : null;
        }
    }

    if (token) {
        setAuthToken(token);
        store.dispatch(initIO(token));
    }

    const state = {
        version: '2.5.2',
        entryPath: window.location.pathname,
        token,
        brand: brand ?? null,
        user: user || (token ? jwtDecode(token) : {}),
        channels: [],
        rooms: [],
        searchResults: [],
        searchResultsATG: [],
        favorites: [],
        meetings: [],
        notification: [],
        nav: 'rooms',
        search: '',
        over: null,
        isPicker: false,
        messages: [],
        streams: [],
        inCall: false,
        video: true,
        audio: true,
        audioStream: null,
        videoStream: null,
        screenStream: null,
        callStatus: null,
        counterpart: null,
        callDirection: null,
        meeting: null,
        showPanel: true,
        panel: 'standard',
        searchPage: '',
        newGroupUsers: [],
        newGroupUsersATG: [],
        openInfo: false,
        showReply: false,
        showModalUpdateTitle: false,
        showModalAddToGroup : false,
        showModalAddTag : false,
        showModalForward : false,
        roomActive : false,
        reply: false,
        messageReply: false,
        showConfirm: false,
        showAlert: false,
        currentMessage: '',
        messageGetData: [],
        messageModeAction: 'new',
        messageForward: [],
        userSelectedTag: [],
        userActiveTag: [],
        customerInfoGlobal: [],
        showPipeline: false,
        routeLists: [],
        parentDomain: '',
        keywordCustomer: ''
    };

    setGlobal(state).then(() => console.log('Global state init complete!'));
}

export default init;
