import React, {useEffect, useState} from 'react';
import {useGlobal} from 'reactn';
import MenuReport from "./components/MenuReport";
import ContentReport from "./components/ContentReport";
import './Report.sass';
import icons from '../../assets';
import getChannelApi from "../../actions/getChannels";

const Report = () => {
    const [selectedChannelId, setSelectedChannelId] = useState("");
    const [selectedPage, setSelectedPage] = useState("");
    const [channelLists, setChannelLists] = useState("");
    const [loading, setLoading] = useState(true);
    let brand = useGlobal('brand')[0];

    useEffect(() => {

        const channelListStorage = localStorage.getItem(`${brand}_channels`);
        const selectedChannelStorage = localStorage.getItem(`${brand}_selected_channel`);

        if(channelListStorage){
            setChannelLists(JSON.parse(channelListStorage));
            getChannelApi().then(res => {
                let channels = res.data.channels;
                if(channels){
                    localStorage.setItem(`${brand}_channels`, JSON.stringify(channels));
                }
            });
        }
        else{
            getChannelApi().then(res => {
                let channels = res.data.channels;
    
                if(channels){
                    setChannelLists(channels);
                    setSelectedPage(channels[0]);
                    setSelectedChannelId(channels[0]._id);
                    localStorage.setItem(`${brand}_channels`, JSON.stringify(channels));
                    localStorage.setItem(`${brand}_selected_channel`, JSON.stringify(channels[0]));
                    setLoading(false);
                }
            })
            .catch(err => console.log(err));
        }

        if(selectedChannelStorage){
            const selected = JSON.parse(selectedChannelStorage);

            if(selected){
                setSelectedPage(selected);
                setSelectedChannelId(selected._id);
                setLoading(false);
            }
        }

    }, []);

    const getSelectedChannel = () => {
        if(!selectedPage) return;

        return (
            <div className='page-selected'>
                <div className='page-icon'>
                    <img src={getAvatarPage(selectedPage)} alt={selectedPage.nameApp}/>
                </div>
                <div className='page-name'>{selectedPage.nameApp}</div>
            </div>
        )
    }

    const getAvatarPage = (channel) => {
        if(channel.source === 'facebook'){
            return `https://graph.facebook.com/v14.0/${channel.socialChanelId}/picture`;
        }
        else if(channel.source === 'zalo'){
            return icons.Zalo;
        }
        else if(channel.avatar){
            return channel.avatar;
        }

        return '';
    }

    const handleClickChannel = (channel) => {
        setSelectedChannelId(channel._id);
        setSelectedPage(channel);
        localStorage.setItem(`${brand}_selected_channel`, JSON.stringify(channel));
    }

    return (
        <div className="report-wrapper">
            <div className='report-header'>
                <div className='container content-header'>
                    <div className='left-header'></div>
                    <div className='right-header'>
                        <div className='page-lists'>
                            {getSelectedChannel()}

                            <div className='dropdown-content'>
                                {!loading && channelLists && channelLists.map((channel, index) => (
                                    <div key={channel._id} className={`page-item ${selectedPage._id === channel._id ? 'd-none' : ''}`} onClick={() => handleClickChannel(channel)}>
                                        <div className='page-item-icon'>
                                            <img src={getAvatarPage(channel)} alt={channel.nameApp}/>
                                            <div className='social-icon'>
                                                {channel.source === 'facebook' ? <img src={icons.Facebook} alt="Icon social" /> : <img src={icons.Zalo} alt="Icon social" />}
                                            </div>
                                        </div>
                                        <div className='page-item-name'>{channel.nameApp}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container page-statistic'>
                <MenuReport/>
                {!loading && <ContentReport selectedChannel={selectedChannelId}/>}
            </div>
        </div>
    );
}

export default Report;
