import React, {useState, useRef, useEffect} from "react";
import './SearchPage.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import Config from '../../../config';
import {useGlobal} from "reactn";
import SearchProduct from "./SearchProduct";
import SearchOrder from "./SearchOrder";
import SearchCustomer from "./SearchCustomer";

const SearchPage = ({showAlertAction, reloadRooms, detectCustomerAction, customerInfo, searchResultCustomer, setSearchResultCustomer}) => {
    const searchPage = useGlobal('searchPage')[0];

    const handleShow = () => {
        return searchPage === 'product' || searchPage === 'order' || searchPage === 'customer' ? 'show' : '';
    }

    return (
        <div className={`search-page-wrap ${handleShow()}`}>
            {searchPage === 'product' && (<SearchProduct showAlertAction={showAlertAction}/>)}
            {searchPage === 'order' && (<SearchOrder showAlertAction={showAlertAction}/>)}
            {searchPage === 'customer' 
            && (<SearchCustomer 
                    customerInfo={customerInfo} 
                    reloadRooms={reloadRooms}
                    detectCustomerAction={detectCustomerAction}
                    searchResultCustomer={searchResultCustomer}
                    setSearchResultCustomer={setSearchResultCustomer}
                    showAlertAction={showAlertAction}/>)}
        </div>
    );
};

export default SearchPage;
