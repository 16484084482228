import React, {useRef, useState, useContext} from 'react';
import "./SearchBar.sass";
import {useGlobal} from "reactn";
import {FiSearch} from 'react-icons/fi';
import searchCustomerApi from "../../../actions/searchCustomer";
import Customer from "./Customer";
import Loading from "./Loading";
import { AppContext } from "../../../context/AppContext";

const SearchCustomer = ({customerInfo, reloadRooms, detectCustomerAction, searchResultCustomer, setSearchResultCustomer, showAlertAction}) => {
    const [keywordCustomer, setKeywordCustomer] = useGlobal('keywordCustomer');
    const [offset, setOffset] = useState(10);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [disableLoad, setDisableLoad] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const searchWrap = useRef(null);
    const { i18n } = useContext(AppContext);

    const onSubmit = () => {
        loadCustomer();
    }

    const onKeydown = (e) => {
        if (e.keyCode === 13) {
            loadCustomer();
        }
    }

    const loadCustomer = () => {
        setLoading(true);
        setOffset(0);
        setDisableLoad(false);

        if(keywordCustomer === ''){
            showAlertAction('warning' , i18n.t('not_enter_keyword'));
            setSearchResultCustomer([]);
            setOffset(0);
            setLoading(false);
            setLoadingMore(false);
            return;
        }

        searchCustomerApi({keyword: keywordCustomer}).then(res => {
            setShowSearch(true);
            if(res.data.error === 0){
                let lists = res.data.data;
                if(lists.length > 0){
                    setOffset(e => e + 10);
                    setSearchResultCustomer(lists);
                }
                else{
                    setSearchResultCustomer([]);
                }
            }
            setLoading(false);
            setLoadingMore(false);
        }).catch(err => {
            setSearchResultCustomer([]);
            setOffset(0);
            setLoading(false);
            setLoadingMore(false);
            showAlertAction('warning' , err);
        });
    }

    const loadCustomerMore = () => {
        searchCustomerApi({keyword: keywordCustomer, offset: offset}).then(res => {
            if(res.data.error === 0){
                let lists = res.data.data;
                if(lists.length > 0){
                    setOffset(e => e + 10);
                    setSearchResultCustomer(customers => [...customers, ...lists]);
                }
                else{
                    setDisableLoad(true);
                }
            }
            setLoading(false);
            setLoadingMore(false);
        }).catch(err => {
            setSearchResultCustomer([]);
            setOffset(0);
            setLoading(false);
            setLoadingMore(false);
            showAlertAction('warning' , err);
        });

    }

    const handleScroll = () => {
        if(loadingMore || disableLoad) return;
        const scrollHeight = searchWrap.current.scrollHeight;
        const currentHeight = Math.ceil(
            searchWrap.current.scrollTop + window.innerHeight
        );
        if (currentHeight + 1 >= scrollHeight) {
            setLoadingMore(true);
            loadCustomerMore();
        }
    };

    return (
        <>
        <div className="search-page-header">
            <div className="mt-2">
                <div className="search-bar search-product uk-flex uk-flex-center uk-flex-middle">
                    <div className="icon" onClick={() => {onSubmit()}}>
                        <FiSearch/>
                    </div>
                    <div className="uk-inline search">
                        <input className="uk-input uk-border-pill" placeholder={i18n.t('enter_phone_name_email')} 
                        value={keywordCustomer} 
                        onChange={(e) => {setKeywordCustomer(e.target.value)}}
                        onKeyDown={(e) => onKeydown(e)} />
                    </div>
                    <button className={`btn btn-primary ${loading ? 'prevent-events' : ''}`} onClick={() => onSubmit()}>{i18n.t('search')}</button>
                </div>
            </div>
        </div>
        {loading && <Loading loading={loading}/>}
        {(!loading && showSearch) && (
            <div className="search-result w-100 mt-2" ref={searchWrap} onScroll={handleScroll}>
                <div className="text-bold mb-2 w-100 text-left">{i18n.t('search_result')}</div>
                <div className="search-result-wrapper">
                    {searchResultCustomer.length > 0 ? 
                        searchResultCustomer.map(customer => 
                            <Customer key={customer.customer_code}
                                customer={customer}
                                detectCustomerAction={detectCustomerAction}
                                userCurrent={customerInfo}
                                reloadRooms={reloadRooms}
                                setSearchResultCustomer={setSearchResultCustomer}
                                showAlertAction={showAlertAction}
                            />)
                        : (<div className="text-center">{i18n.t('no_result')}</div>)
                    }
                    {loadingMore && (<div className="text-center">{i18n.t('loading')}...</div>)}
                </div>
            </div>
        )}
        </>
    );
}

export default SearchCustomer;
