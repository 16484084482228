import axios from "axios";
import Config from "../config";

const searchCustomer = (data) => {
    const {keyword, offset = 0, limit = 10} = data;
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/customer/search",
        data: { keyword, limit, offset }
    });
};

export default searchCustomer;
