import React, {useEffect} from 'react';

const TopTag = ({tags}) => {
    useEffect(() => {
        
    }, []);

    return (
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Top thẻ hội thoại</div>
                    <div className="desc">Các thẻ đang được gắn nhiều nhất</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content">
            {tags && tags.length > 0 && (
                <div className='top-tags-content'>
                    {tags.map((tag, index) => tag.tag_name && (<div key={tag.tag_name} className="item">
                        <div className="tag-item">
                            <div className="icon-and-name">
                                <div className="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={tag?.color || '#1a8411'} viewBox="0 0 256 256">
                                        <path d="M243.31,136,144,36.69A15.86,15.86,0,0,0,132.69,32H40a8,8,0,0,0-8,8v92.69A15.86,15.86,0,0,0,36.69,144L136,243.31a16,16,0,0,0,22.63,0l84.68-84.68a16,16,0,0,0,0-22.63ZM84,96A12,12,0,1,1,96,84,12,12,0,0,1,84,96Z"></path></svg>
                                        </div>
                                <div className="name">{tag.tag_name}</div>
                            </div>
                            <div className="usage-count">{tag.count}</div>
                        </div>
                    </div>))}
                </div>
            )}
            </div>
        </div>
    );
};

export default TopTag;
