import React, {useState, useEffect, useRef, useContext} from "react";
import {useSelector} from "react-redux";
import parseHtml from 'html-react-parser'
import getListNoteApi from "../../../actions/getListNote";
import createNoteApi from "../../../actions/createNote";
import updateNoteApi from "../../../actions/updateNote";
import deleteNoteApi from "../../../actions/deleteNote";
import MasterConstant from "../../../constants/MasterConstant";
import SweetAlert from "react-bootstrap-sweetalert";
import { AppContext } from "../../../context/AppContext";
import {FiEdit, FiDelete, FiArrowLeft, FiCheck} from "react-icons/fi";

const Notes = React.memo(function Notes({showNote, setShowNote, setShowAlert, showAlertAction, hideAlertAction}) {
    const room = useSelector(state => state.io.room);
    const notesRef = useRef([]);
    const [listNote, setListNote] = useState([]);
    const [valueNote, setValueNote] = useState('');
    const [valueNoteUpdate, setValueNoteUpdate] = useState('');
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [offset, setOffset] = useState(0);
    const { i18n } = useContext(AppContext);
    const limit = 10;
    const limitNoteOnLoad = 5;

    useEffect(() => {
        let isMounted = true;
        getListNoteApi({room_id: room._id, offset, limit: limitNoteOnLoad}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                if(isMounted){
                    setListNote(result);
                    setOffset(e => e + limitNoteOnLoad);

                    if(result.length > 2){
                        setShowLoadMore(true);
                    }
                }
            }
        }).catch(err => console.log('getListNoteApi', err));
        
        return () => {
            isMounted = false;
        };
    }, [room]);

    const getListNote = () => {
        let limitLoad = 0;

        if(offset === limitNoteOnLoad){
            limitLoad = limitNoteOnLoad;
        }
        else{
            limitLoad = offset + limit;
        }

        getListNoteApi({room_id: room._id, offset: 0, limit: limitLoad}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setListNote(result);
                if(offset === limitNoteOnLoad){
                    result.length >= limitNoteOnLoad ? setShowLoadMore(true) : setShowLoadMore(false);
                }
                else{
                    result.length >= 10 ? setShowLoadMore(true) : setShowLoadMore(false);
                }
            }
        }).catch(err => console.log('getListNoteApi', err));
    }

    const getMoreListNote = () => {
        getListNoteApi({room_id: room._id, offset, limit}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setOffset(e => e + limit);
                setListNote(e => [...e, ...result]);
                result.length >= limit ? setShowLoadMore(true) : setShowLoadMore(false);
            }
        }).catch(err => console.log('getListNoteApi', err));
    }

    const addNotes = () => {
        if(valueNote.trim() === ''){
            showAlertAction('warning', i18n.t('not_note_content'));
            return;
        }

        createNoteApi({room_id: room._id, content: valueNote}).then(res => {
            if(res.data.error === 0 && res.data.data){
                // let result = res.data.data;
                setValueNote('');
                // setListNote(e => ([...e, ...[result]]));
                getListNote()
                setShowNote(false);
            }
        }).catch(err => console.log('createNoteApi', err));
    }

    const deleteNotes = (note_id) => {
        deleteNoteApi({note_id, room_id: room._id}).then(res => {
            if(res.data.error === 0){
                getListNote()
                showAlertAction('success', res.data.message);
            }
        }).catch(err => console.log('createNoteApi', err));
    }

    const showUpdateNote = (index, content) => {
        setValueNoteUpdate(content);
        notesRef.current[index].children[0].classList.add('hide');
        notesRef.current[index].children[1].classList.remove('hide');
    }

    const handbleOnChangeValueNote = (e, i) => {
        const notes = [...listNote];
        const content = e.target.value;

        notes[i]['content'] = content;
        setListNote(notes);
    }

    const updateNotes = (index, note_id) => {
        const notes = [...listNote];
        const noteContent = notes[index]['content'];

        if(noteContent.trim() === ''){
            showAlertAction('warning', i18n.t('not_note_content'));
            return;
        }

        updateNoteApi({note_id: note_id, room_id: room._id, content: noteContent}).then(res => {
            if(res.data.error === 0){
                getListNote()
                cancelUpdateNotes(index);
                showAlertAction('success', res.data.message);
            }
        }).catch(err => console.log('updateNoteApi', err));
    }

    const cancelAddNotes = () => {
        setShowNote(false);
    }

    const cancelUpdateNotes = (index) => {
        const notes = [...listNote];
        notes[index]['content'] = valueNoteUpdate;
        setListNote(notes);
        
        notesRef.current[index].children[0].classList.remove('hide');
        notesRef.current[index].children[1].classList.add('hide');
    }

    const showConfirmDeleteNote = (note_id, message, title = '') => {
        // handleSetLoading(true);
        setShowAlert(<SweetAlert 
            warning
            showCancel
            confirmBtnText={i18n.t('confirm')} 
            cancelBtnText={i18n.t('cancel')} 
            confirmBtnBsStyle="danger"
            title={title}
            btnSize="sm" 
            onConfirm={() => deleteNotes(note_id)} 
            onCancel={() => hideAlertAction()}>
                {message}
            </SweetAlert>
        )
    }

    return (
        <>
            {showNote && (
                <div className="detail-action-item">
                    <div className="note-content">
                        <textarea className="form-control" onChange={(e) => {setValueNote(e.target.value)}} placeholder={`${i18n.t('enter_note_content')}...`} value={valueNote}></textarea>
                        <div className="mt-2 mb-2 text-right">
                            <button className="btn btn-default mr-2" onClick={() => cancelAddNotes()}><FiArrowLeft/> {i18n.t('cancel')}</button>
                            <button className="btn btn-primary" onClick={() => addNotes()}><FiCheck/> {i18n.t('save')}</button>
                        </div>
                    </div>
                </div>
            )}

            {listNote.length > 0 && (
                <div className="note-lists p-2">
                    <div className="mb-2 text-bold">{i18n.t('note')}</div>
                    {listNote.map((note, index) => (<div key={note._id} className="item" ref={(ele) => {notesRef.current[index] = ele}}>
                        <div className="note-action">
                            <div className="time-action">
                                <div className="time">{MasterConstant.timeSince(note.createdAt, i18n, 'today')}</div>
                                <div>
                                    <span className="mr-2 btn-update" onClick={(e) => showUpdateNote(index, note.content)}><FiEdit/></span>
                                    <span className="btn-delete" onClick={() => showConfirmDeleteNote(note._id, i18n.t('confirm_delete_note'))}><FiDelete/></span>
                                </div>
                            </div>
                            <div>{parseHtml(MasterConstant.nl2br(note.content))}</div>
                        </div>
                        <div className="note-content hide">
                            <textarea className="form-control" onChange={(e) => {handbleOnChangeValueNote(e, index)}} value={note.content} placeholder={`${i18n.t('enter_note_content')}...`}></textarea>
                            <div className="mt-2 mb-2 text-right">
                                <button className="btn btn-default mr-2" onClick={() => cancelUpdateNotes(index)}><FiArrowLeft/> {i18n.t('cancel')}</button>
                                <button className="btn btn-danger mr-2" onClick={() => showConfirmDeleteNote(note._id, i18n.t('confirm_delete_note'))}><FiDelete/> {i18n.t('delete')}</button>
                                <button className="btn btn-primary" onClick={() => updateNotes(index, note._id)}><FiEdit/> {i18n.t('save')}</button>
                            </div>
                        </div>
                    </div>))}
                    {showLoadMore && (<div className="load-more" onClick={() => getMoreListNote()}>{i18n.t('view_more')}</div>)}
                </div>
            )}
        </>
    );
});

export default Notes;
