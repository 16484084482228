import axios from "axios";
import Config from "../config";

const updateNote = (data) => {
    const { note_id, room_id, content } = data;
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/notes/update",
        data: {note_id, room_id, content}
    });
};

export default updateNote;
