import React, {useEffect, useState, useContext} from 'react';
import {useGlobal} from "reactn";
import {Button, Form, Modal} from "react-bootstrap";
import getChannels from "../../../actions/getChannels";
import {FiSettings, FiArrowLeft, FiRefreshCcw} from "react-icons/fi";
import Select from 'react-select';
import './SettingModal.sass';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { AppContext } from "../../../context/AppContext";
import syncMessageAction from "../../../actions/syncMessage";
import getRooms from "../../../actions/getRooms";
import Actions from "../../../constants/Actions";
import store from "../../../store";

const SettingModal = ({showModalSetting, setShowModalSetting}) => {
    const [selectedChannel, setSelectedChannel] = useState("");
    const [channelLists, setChannelLists] = useState("");
    const { showAlertAction, isLoading, setIsLoading, i18n } = useContext(AppContext);

    const socialActive = useGlobal('socialActive')[0];
    const activeChannel = useGlobal('activeChannel')[0];
    const activeMessageStatus = useGlobal('activeMessageStatus')[0];
    const activeTags = useGlobal('activeTags')[0];
    const searchText = useGlobal('search')[0];

    useEffect(() => {
        getChannels().then(res => {
            let channels = res.data.channels;
            let channelLists = channels.map(channel => ({value: channel._id, label: channel.nameApp}));
            setChannelLists(channelLists);
        })
        .catch(err => console.log(err));
    }, []);

    const handleChangeStatus = (selected) => {
        setSelectedChannel(selected);
    };

    const reloadRooms = () => {
        getRooms(searchText, socialActive, activeChannel, activeMessageStatus, activeTags)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const syncMessage = () => {
        if(isLoading) return;

        if(!selectedChannel){
            setShowModalSetting(false);
            showAlertAction('warning', i18n.t('not_select_channel'));
            return;
        }

        setIsLoading(true);
        syncMessageAction(selectedChannel.value).then(res => {
            if(res.data.error === 0){
                setIsLoading(false);
                setShowModalSetting(false);
                showAlertAction('success', i18n.t('sync_message_success'));
                reloadRooms();
            }
            else{
                setIsLoading(false);
                setShowModalSetting(false);
                showAlertAction('warning', i18n.t('no_message_sync'))
            }
        })
        .catch(err => {
            console.log(err)
            setIsLoading(false);
            setShowModalSetting(false);
            showAlertAction('warning', i18n.t('sync_message_failed'))
        });
    }

    return (
        <Modal
                show={showModalSetting}
                onHide={() => setShowModalSetting(false)} animation={false}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="modal-setting"
            >
                <Form noValidate>
                    <Modal.Header closeButton>
                        <div className="modal-title h6"><FiSettings/> {i18n.t('config')}</div>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey="home"
                            id="setting-tabs"
                            className="mb-3"
                        >
                            <Tab eventKey="home" title={i18n.t('sync_message')}>
                                <span>{i18n.t('choose_channel')}</span>

                                <div className="select-channel-setting mt-2">
                                    <Select
                                        value={selectedChannel}
                                        onChange={handleChangeStatus}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={channelLists[0]}
                                        isSearchable="true"
                                        placeholder={`${i18n.t('choose_channel')}...`}
                                        options={channelLists}
                                    />
                                </div>

                                <div className={`mt-3 btn btn-primary ${isLoading ? 'prevent-events' : ''}`} onClick={() => syncMessage(false)}><FiRefreshCcw className={isLoading ? 'rorate' : ''} /> {isLoading ? `${i18n.t('synchronizing')}...` : i18n.t('sync')}</div>
                            </Tab>
                            {/* <Tab eventKey="profile" title="Profile">
                                Tab content for Profile
                            </Tab>
                            <Tab eventKey="contact" title="Contact">
                                Tab content for Contact
                            </Tab> */}
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalSetting(false)}><FiArrowLeft/> {i18n.t('hide')}</Button>
                        {/* <Button onClick={() => submitSetting()}><FiCheck/> Lưu</Button> */}
                    </Modal.Footer>
                </Form>
            </Modal>
    );
}

export default SettingModal;
