import React, {useRef, useState, useEffect, useCallback, useContext} from 'react';
import "./BottomBarNotSend.sass";
import {useGlobal} from "reactn";
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import message from "../../../actions/message";
import uploadImage from "../../../actions/uploadImage";
import uploadFile from "../../../actions/uploadFile";
import {useDispatch, useSelector} from "react-redux";
import ZaloMessTransModal from "./ZaloMessTransModal";

import Config from '../../../config';
import store from "../../../store";
import { AppContext } from '../../../context/AppContext';
import { Button } from 'antd';

const BottomBarNotSend = () => {

    const [showModalZaloMessTrans, setShowModalZaloMessTrans] = useState(false);
    const { i18n } = useContext(AppContext);


    return (
        <>
        <div className={`bottom-bar-not-send action-event`}>
            <Button type="primary" onClick={() => setShowModalZaloMessTrans(true)}><strong>Gửi tin nhắn tương tác</strong></Button>
        </div>
        {showModalZaloMessTrans && 
            <ZaloMessTransModal 
                showModal={showModalZaloMessTrans} 
                setShowModal={setShowModalZaloMessTrans} 
            />}
        </>
    );
}

export default BottomBarNotSend;
