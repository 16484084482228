import axios from "axios";
import Config from "../config";

const updateCustomer = (data) => {
    return axios({
        method: "post",
        url: (Config.url || '') + `/api/customer/update/${data.id}`,
        data: { type_customer: data.customer_type, data: data.data }
    });
};

export default updateCustomer;
