import React, {useRef, useState, useContext} from 'react';
import "./SearchBar.sass";
import {FiSearch} from 'react-icons/fi';
import searchOrderApi from "../../../actions/searchOrder";
import Order from "./Order";
import Loading from "./Loading";
import { AppContext } from "../../../context/AppContext";

const SearchOrder = ({showAlertAction}) => {
    const[keyword, setKeyword] = useState(""); 
    const [searchResultOrder, setSearchResultOrder] = useState([]);
    const [pageCurrent, setPageCurrent] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [disableLoad, setDisableLoad] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const searchWrap = useRef(null);
    const { i18n } = useContext(AppContext);

    const onSubmit = () => {
        loadOrder();
    }

    const onKeydown = (e) => {
        if (e.keyCode === 13) {
            loadOrder();
        }
    }

    const loadOrder = () => {
        setLoading(true);
        setPageCurrent(1);
        setDisableLoad(false);

        if(keyword === ''){
            showAlertAction('warning' , i18n.t('not_enter_keyword'));
            setSearchResultOrder([]);
            setPageCurrent(1);
            setLoading(false);
            setLoadingMore(false);
            return;
        }

        searchOrderApi({keyword}).then(res => {
            setShowSearch(true);
            if(res.data.error === 0){
                let lists = res.data.data.Items;
                if(lists.length > 0){
                    setPageCurrent(page => page + 1);
                    setSearchResultOrder(lists);
                }
                else{
                    setSearchResultOrder([]);
                }
            }
            setLoading(false);
            setLoadingMore(false);
        }).catch(err => {
            setSearchResultOrder([]);
            setPageCurrent(1);
            setLoading(false);
            setLoadingMore(false);
            showAlertAction('warning' , err);
        });
    }

    const loadOrderMore = () => {
        searchOrderApi({keyword, page: pageCurrent}).then(res => {
            let lists = res.data.data.Items;
            if(res.data.error === 0){
                if(lists.length > 0){
                    setPageCurrent(page => page + 1);
                    setSearchResultOrder(orders => [...orders, ...lists]);
                }
                else{
                    setDisableLoad(true);
                }
            }
            setLoading(false);
            setLoadingMore(false);
        }).catch(err => {
            setSearchResultOrder([]);
            setPageCurrent(1);
            setLoading(false);
            setLoadingMore(false);
            showAlertAction('warning' , err);
        });
    }

    const handleScroll = () => {
        if(loadingMore || disableLoad) return;
        const scrollHeight = searchWrap.current.scrollHeight;
        const currentHeight = Math.ceil(
            searchWrap.current.scrollTop + window.innerHeight
        );
        if (currentHeight + 1 >= scrollHeight) {
            setLoadingMore(true);
            loadOrderMore();
        }
    };

    return (
        <>
        <div className="search-page-header">
            <div className="mt-2">
                <div className="search-bar search-product uk-flex uk-flex-center uk-flex-middle">
                    <div className="icon" onClick={() => {onSubmit()}}>
                        <FiSearch/>
                    </div>
                    <div className="uk-inline search">
                        <input className="uk-input uk-border-pill" placeholder={i18n.t('enter_order_code')}
                        value={keyword} 
                        onChange={(e) => {setKeyword(e.target.value)}}
                        onKeyDown={(e) => onKeydown(e)} />
                    </div>
                    <button className={`btn btn-primary ${loading ? 'prevent-events' : ''}`} onClick={() => onSubmit()}>{i18n.t('search')}</button>
                </div>
            </div>
        </div>
        {loading && <Loading loading={loading}/>}
        {(!loading && showSearch) && (
            <div className="search-result w-100 mt-2" ref={searchWrap} onScroll={handleScroll}>
                <div className="text-bold mb-2 w-100 text-left">{i18n.t('search_result')}</div>
                <div className="search-result-wrapper">
                    {searchResultOrder.length > 0 ? 
                        searchResultOrder.map(order => 
                            <Order key={order.order_id} 
                                order={order} 
                            />)
                        : (<div className="text-center">{i18n.t('no_result')}</div>)
                    }
                    {loadingMore && (<div className="text-center">{i18n.t('loading')}...</div>)}
                </div>
            </div>
        )}
        </>
    );
}

export default SearchOrder;
