import axios from "axios";
import Config from "../../config";

const ReportDetailPageByDate = params => {
    const {channel_id, date} = params
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/report/detail-page-by-date",
        data: { channel_id, date }
    });
};

export default ReportDetailPageByDate;
