import {React, useContext} from 'react';
import "./TopBar.sass";
import {FiArrowLeft} from 'react-icons/fi';
import {useGlobal} from "reactn";
import { AppContext } from "../../../context/AppContext";

const TopBar = ({back}) => {
    const [searchPage, setSearchPage] = useGlobal('searchPage');
    const setKeywordCustomer = useGlobal('keywordCustomer')[1];
    const { i18n } = useContext(AppContext);

    const handleBack = () => {
        setSearchPage('');
        setKeywordCustomer('');
    }

    const getTitleName = () => {
        switch (searchPage) {
            case 'product':
                return i18n.t('search_product_info');
            case 'order':
                return i18n.t('search_order_info');
            case 'customer':
                return i18n.t('search_customer_info');
            default:
                return i18n.t('customer_info');
        }
    }
    
    return (
            <div className="top-bar detail-customer uk-flex uk-flex-center uk-flex-middle">
                <div className="nav">
                    {(searchPage === 'product' || searchPage === 'order' || searchPage === 'customer') &&
                    (<div className="button" onClick={() => handleBack()}>
                        <FiArrowLeft/>
                    </div>)}
                    <p className="mb-0 panel-title">{getTitleName}</p>
                </div>
                {/* <div className="nav">
                    {Config.demo && <div className="uk-inline">
                        <div className="button" type="button">
                            <FiMoreHorizontal/>
                        </div>
                    </div>}
                </div> */}
            </div>
    );
}

export default TopBar;
