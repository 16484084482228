import axios from "axios";
import Config from "../config";

const removeLinkCustomer = (data) => {
    let {user_id, customer_id, customer_lead_id} = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/customer/remove-link",
        data: {user_id, customer_id, customer_lead_id }
    });
};

export default removeLinkCustomer;
