import ClipLoader from "react-spinners/ClipLoader";

const Loading = ({loading}) => {

    return (
        <div className="content uk-flex uk-flex-center uk-flex-middle uk-flex-column loading-block loading-more">
            <ClipLoader
                size={20}
                color={"#0067ac"}
                loading={loading}
            />
        </div>
    );
};

export default Loading;
