import axios from "axios";
import Config from "../../config";

const ReportTag = params => {
    const {channel_id, limit, start_date, end_date} = params
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/report/tag",
        data: { channel_id, limit, start_date, end_date }
    });
};

export default ReportTag;
