import axios from "axios";
import Config from "../config";

const getRooms = (keyword = null, source = null, channel_id = null, activeMessageStatus = null, activeTags = [], page = 1) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/rooms/list",
        data: { page, keyword, source, channel_id, status: activeMessageStatus, tag_ids: activeTags }
    });
};

export default getRooms;
