import axios from "axios";
import Config from "../../config";

const ReportRatioStaff = params => {
    const {channel_id, start_date, end_date} = params
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/report/ratio-staff",
        data: { channel_id, start_date, end_date }
    });
};

export default ReportRatioStaff;
