import axios from "axios";
import Config from "../config";

const updateMappingCustomer = (data) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/mapping-customer/update",
        data: data
    });
};

export default updateMappingCustomer;
