import React, {useEffect, useState} from 'react';
import { Spin} from 'antd';
import LineChart from "../Chart/LineChart";
import TopStaff from "../Block/TopStaff";
import TopTag from "../Block/TopTag";
import ReportOverViewApi from "../../../../api/report/ReportOverView";
import ReportTagApi from "../../../../api/report/ReportTag";
import icons from '../../../../assets';

const OverviewReport = ({selectedChannel, selectedTime}) => {
    const [overviewChart, setOverviewChart] = useState();
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true; 

        const start_date = selectedTime[0];
        const end_date = selectedTime[1];
        ReportOverViewApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;

            if(isMounted && data){
                getDataOverViewLineChart(data);
                setLoading(false);
            }
        })
        .catch(err => console.log(err));

        ReportTagApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;

            if(isMounted && data){
                let dataList = data.lists;
                setTags(dataList);
            }
        })
        .catch(err => console.log(err));

        return () => {
            isMounted = false; // Đặt biến isMounted thành false khi component unmount
        };

    }, [selectedChannel, selectedTime]);

    const optionsOverview = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        let index = context[0].dataIndex;
                        let dataChart = overviewChart;
                        let date = dataChart.allDates[index];
                        date = date.split('-');
                        let day = date[2];
                        let month = date[1];

                        return `Ngày ${day} tháng ${month}`;
                    }
                }
            }
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        scales: {
            x: {
                grid: {
                    display: false, // Ẩn đường kẻ dọc trên trục x
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Tương tác' // Label mô tả cho trục x
                }
            }
        },
        maintainAspectRatio: false,
        aspectRatio: 1
        // elements: {
        //     point: {
        //       radius: 0, // Đặt kích thước của dấu chấm điểm là 0 để ẩn chúng
        //     },
        // },
    };

    const getDataOverViewLineChart = (data) => {
        const result = {
            allDates: data.allDates,
            totalMessage: data.totalMessage,
            totalRoom: data.totalRoom,
            labels: data.labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Tổng tương tác',
                    data: data?.values,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    tension: 0.3
                },
                {
                    type: 'line',
                    label: 'Hội thoại mới',
                    data: data?.valueRooms,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    tension: 0.3
                },
            ],
        };

        setOverviewChart(result);
    }

    return (
        <>
        <div className='activity-overview'>
            <div className="card">
                <div className="header">
                    <div className="title-desc">
                        <div className="title">Tổng quan về hoạt động</div>
                        <div className="desc">Thống kê tổng quan</div>
                    </div>
                    <div className='interaction'></div>
                </div>
                <div className="content">
                    <div className="chart-container">
                        <Spin tip="Loading..." spinning={loading}>{loading ? <div className="loading-content"/> : <LineChart options={optionsOverview} data={overviewChart}/>}</Spin>
                    </div>
                </div>
            </div>
        </div>
        <div className='other-overview'>
            <div className='flex-c'>
                <div className="card">
                    <div className="header">
                        <div className="title-desc">
                            <div className="title">Tổng quan về trang</div>
                            <div className="desc">Một số thông tin về trang của bạn</div>
                        </div>
                        <div className='interaction'></div>
                    </div>
                    <div className="content">
                        <div className="page-overview-content">
                            <div className="page-inbox">
                                <div className="icon">
                                    <div className="frame"></div>
                                    <img src={icons.PageInbox} alt="Page inbox"/>
                                </div>
                                <div className="content">
                                    <div className="desc">Tin nhắn tới trang</div>
                                    <div className="count">{overviewChart?.totalMessage}</div>
                                </div>
                            </div>
                            <div className="page-inbox">
                                <div className="icon">
                                    <div className="frame"></div>
                                    <img src={icons.PageComment} alt="Page Comment"/>
                                </div>
                                <div className="content">
                                    <div className="desc">Tương tác mới</div>
                                    <div className="count">{overviewChart?.totalRoom}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <TopTag tags={tags}/>
            </div>
            <div className='flex-c'>
                <TopStaff selectedChannel={selectedChannel} selectedTime={selectedTime}/>
            </div>
        </div>
        </>
    );
};

export default OverviewReport;
