import axios from "axios";
import Config from "../config";

const clearMessage = data => {
    const { roomId } = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/message/clear",
        data: { roomId }
    });
};

export default clearMessage;
