import axios from "axios";
import Config from "../config";

const deleteNote = (data) => {
    const { note_id, room_id } = data;
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/notes/delete",
        data: {note_id, room_id}
    });
};

export default deleteNote;
