import React, {useContext} from 'react';
import moment from "moment";
import './Message.sass';
import './Message.css';
import emojiRegex from 'emoji-regex/text';
import Config from "../../../config";
import {useGlobal} from "reactn";
import ReactImageAppear from 'react-image-appear';
import {FiDownloadCloud, FiCheckCircle, FiInfo} from "react-icons/fi";
import striptags from "striptags";
import { saveAs } from 'file-saver';
import pdfIcon from "../../../assets/icon-pdf.png";
import wordIcon from "../../../assets/icon-word.png";
import excelIcon from "../../../assets/icon-excel.png";

import {Image, Tooltip, OverlayTrigger} from "react-bootstrap";
import MasterConstant from "../../../constants/MasterConstant";
import ReactTooltip from "react-tooltip";
import Actions from "../../../constants/Actions";
import createRoom from "../../../actions/createRoom";
import {useHistory, useLocation} from "react-router-dom";
import store from "../../../store";
import icons from "../../../assets";
import { AppContext } from "../../../context/AppContext";

const Message = ({message, previous, next, onOpen, messageSeen, room}) => {

    const [nav, setNav] = useGlobal('nav');
    const brand = useGlobal('brand')[0];
    let { author, content, date, staff } = message;
    const history = useHistory();
    const location = useLocation();
    const { i18n } = useContext(AppContext);

    const user = useGlobal('user')[0];
    const totalMessageSeen = messageSeen.length - 1;

    if (!author) author = { firstName: 'Deleted', lastName: 'User' };
    if (previous && !previous.author) previous.author = { firstName: 'Deleted', lastName: 'User' };
    if (previous && !previous.staff) previous.staff = { staff_id: null, full_name: '' };
    if (next && !next.author) next.author = { firstName: 'Deleted', lastName: 'User' };

    const isMine = user.id === author._id;

    let attachPrevious = false, attachNext = false;

    if (previous && Math.abs(moment(previous.date).diff(moment(date), 'minutes')) < 3  
    && author._id === previous.author._id 
    && (staff && staff.staff_id === previous.staff.staff_id))
        attachPrevious = true;
    if (next && Math.abs(moment(next.date).diff(moment(date), 'minutes')) < 3 && author._id === next.author._id)
        attachNext = true;

    const Picture = ({user, channel}) => {
        if (user && user.picture){
            return <img onClick={startChat} className="img" src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256/${brand}`} alt="chat" />;
        }
        else{
            if(channel){
                let imgSrc = channel.source === 'facebook' ? `https://graph.facebook.com/v14.0/${channel.socialChanelId}/picture` : icons.Zalo;
                return <img onClick={startChat} className="img" src={imgSrc} alt="chat" />
            }
            else{
                return <div onClick={startChat} className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
            }
        }
    };

    const Details = ({side, message}) => {
        if (!attachNext || message.error_message){
            const tooltip = <Tooltip>{message.error_message}</Tooltip>;
            return (
                <div className={`message-details ${side}`}>
                    {message.error_message ? (
                        <OverlayTrigger placement="bottom" overlay={tooltip}>
                            <p style={{color: "#ff5959", cursor: "pointer", margin: 0}}> <FiInfo/> {i18n.t('message_not_send')}</p>
                        </OverlayTrigger>
                    ) : ""}
                    <span>{MasterConstant.timeSince(date, i18n, 'today')}</span>
                </div>
            );
        }
        else return null;
    };

    const PictureOrSpacer = () => {
        if (attachPrevious) return <div className="spacer"/>;
        else return (
            <div className="picture">
                <PictureAdmin user={author} staff={staff} room={room}/>
            </div>
        );
    };

    const PictureAdmin = ({user, staff, room}) => {
        const channel = room.channel;

        if(isMine){ //Admin
            if (staff && staff.staff_avatar){
                return <img className="img" src={staff.staff_avatar} alt="Admin chat"/>;
            }
            else if(channel){
                let imgSrc = channel.source === 'facebook' ? `https://graph.facebook.com/v14.0/${channel.socialChanelId}/picture` : icons.Zalo;
                return <img className="img" src={imgSrc} alt="Admin chat" />
            }
        }
        else{ //UserChat
            if (room && room.shieldedID){
                return <img className="img" src={`${Config.url || ''}/api/images/${room.shieldedID}/256/${brand}`} alt="User Chat" />;
            }
            else if(user && user.picture){
                return <img className="img" src={`${Config.url || ''}/api/images/${user.picture.shieldedID}/256/${brand}`} alt="User Chat" />;
            }
        }
        
        return <div className="img">{user.firstName.substr(0,1)}{user.lastName.substr(0,1)}</div>;
    };

    const noEmoji = content ? content.replace(emojiRegex(), '') : '';
    const isOnlyEmoji = !noEmoji.replace(/[\s\n]/gm, '');

    const getBubble = () => {
        if (attachPrevious || isOnlyEmoji)
            if (isMine) return ' right';
            else return ' left';
        if (isMine) return ' bubble-right right';
        else return ' bubble-left left';
    };

    const downloadImage = (image_url,image_name) => {
        saveAs(image_url, image_name) // Put your image url here.
    }

    const bytesToSize = (bytes) => {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const getIconFile = (type) => {
        if(type === 'pdf'){
            return pdfIcon;
        }
        else if(type === 'docx'){
            return wordIcon;
        }
        else if(type === 'xlsx'){
            return excelIcon;
        }
        else{
            return null;
        }
    }

    const Content = ({dataContent}) => {

        if(!dataContent){
            dataContent = message;
        }

        switch (dataContent.type) {
            case 'image':
                return (
                    <div className="content-image">
                        <div className="btn-download-img" 
                            onClick={() => downloadImage(`${Config.url || ''}/api/images/${dataContent.content}/${brand}`, dataContent.image.name)} >
                            <div className="content-icon"><FiDownloadCloud/></div>
                        </div>

                        <ReactImageAppear
                            src={`${Config.url || ''}/api/images/${dataContent.content}/256/${brand}`}
                            animationDuration="0.2s"
                            onClick={() => onOpen(dataContent)}
                        />
                    </div>

                );
            case 'sticker':
                return (
                    <div className="content-sticker">
                        <img src={dataContent.sticker} alt="sticker" />
                    </div>  
                );
            case 'system':
                return (
                    <div className="content-system">
                        <FiCheckCircle size="16" color={'#31cb31'}/>
                        <span style={{ marginLeft: "5px" }}>{dataContent.content}</span>
                    </div>
                );
            case 'file':
                let file_type = getIconFile(dataContent?.file?.type);
                return (
                    <a className="file-attach" href={`${Config.url || ''}/api/files/${dataContent.content}/${brand}`} download={dataContent.file ? dataContent.file.name : 'File'}>
                        <div className="content-download">
                            {file_type ? <div className="icon-file"><Image width="30" src={file_type}/></div> : ""}
                            <div className="content-file">
                                <div className="content-name">{dataContent.file ? dataContent.file.name : 'File'}</div>
                                <div className="content-size">{dataContent.file ? `${bytesToSize(dataContent.file.size)}` : "Size"}</div>
                            </div>
                            <div className="content-icon"><FiDownloadCloud/></div>
                        </div>
                    </a>
                );
            default:
                let finalContent = dataContent.content;
                if(finalContent){
                    let tagFullName;
                    let tagUser = finalContent.match(/@((?!@).)*-((?!@).)*@/gm);
                    if(tagUser){
                        tagUser.map(item => {

                            let tag = item.replace(/@/g, '');
                            let objTag = tag.split('-');
                            tagFullName = objTag[0];
                            finalContent = finalContent.replace(item, '<strong className="tagUser">@' + tagFullName + '</strong> ');
                        });
                    }


                    return(
                            <div dangerouslySetInnerHTML={{__html: MasterConstant.convertUrls(striptags(MasterConstant.nl2br(finalContent), [ 'a', 'strong', 'b', 'i', 'em', 'u', 'br' ]))}} />
                    );
                }
                return(
                    <div />
                );
        }
    };

    const getBubbleClass = () => {
        if (message.type === 'image') return 'bubble-image';
        return 'bubble';
    };

    const Replies = () => {
        const replies = message.replies;
        return(
            <div className="reply_message">
                <div className="block_reply_message">
                    <div><strong>{replies?.author?.firstName} {replies?.author?.lastName}</strong></div>
                    <span><Content dataContent={replies}  /></span>
                </div>
            </div>
        );
    }

    const startChat = async () => {

        if(isMine) return;
        const res = await createRoom(author._id);
        setNav('rooms');
        const target = `/room/${res.data.room._id}`;
        if (location.pathname !== target) history.replace(target);
        store.dispatch({type: Actions.SET_ROOM, room: res.data.room});
        store.dispatch({type: Actions.SET_MESSAGES, messages: res.data.room.messages});

    }

    const ReactionMessage = (emoji) => {
        return(
            <div>
                {message.reaction_total > 0 && (<div className={`list-emoji-check ${isMine ?'list-emoji-right' : 'list-emoji-left'}`}>
                        <span
                            data-tip data-for={`reaction_people_${message._id}`}
                            className={`emoji-message cursor-pointer`}>
                            {isMine && (<span className="number_emoji number_emoji_right">{message.reaction_total}</span>)}
                            {message.reaction_like.length > 0 && (<span className='emoji_detail'>👍</span>)}
                            {message.reaction_heart.length > 0 && (<span className='emoji_detail'>❤️</span>)}
                            {message.reaction_haha.length > 0 && (<span className='emoji_detail'>😆</span>)}
                            {message.reaction_amazing.length > 0 && (<span className='emoji_detail'>😲</span>)}
                            {message.reaction_like.length > 0 && (<span className='emoji_detail'>😭</span>)}
                            {message.reaction_like.length > 0 && (<span className='emoji_detail'>😡</span>)}
                            {!isMine && (<span className="number_emoji number_emoji_left">{message.reaction_total}</span>)}
                            <ReactTooltip id={`reaction_people_${message._id}`} type='error'>
                                {message.reaction_people.map(reaction_people=>{
                                    return(<div key={`reaction_people_${message._id}_${reaction_people._id}`}>{reaction_people.firstName} {reaction_people.lastName}</div>);
                                })}
                            </ReactTooltip>
                        </span>
                </div>)}
            </div>

        );
    }

    const MessageAction = () => {
        return(
            <ReactTooltip clickable={true} place="right" backgroundColor='none' id={`message_action_${message._id}`} className='extraClass' delayHide={200} effect='solid'>
                <div>
                    <div  className={`action_message action_message_show ${isMine ? 'action_message_right' : 'action_message_left' }`}>
                        <div className="menu-action"></div>
                    </div>
                </div>
            </ReactTooltip>
        );
    }

    const MessageSeen = () => {
        return(
            <div className="avatar_view message">
                <div className="picture">
                    <div className="text_seen_by">seen by </div>
                    { messageSeen.slice(0,3).map(messageS => {
                        if(message.author && messageS.author){
                            if( message.author._id !== messageS.author._id ) {
                                return <Picture user={messageS.author} channel={room.channel} key={`MessageSeen_Pic_${message._id}_${messageS.author._id}`} />
                            }
                            else
                            {
                                return null;
                            }
                        } else {
                            return null;
                        }

                    })}
                    { totalMessageSeen > 3 ?? (
                        <span className="number_view">{totalMessageSeen - 3}</span>
                    )}
                </div>
            </div>
        )
    }

    const getAuthorName = () => {
        if(staff && staff.full_name){
            return staff.full_name;
        }

        if(author && author.firstName){
            return author.firstName;
        }

        return 'Admin';
    }

    return (
        <div>
            <div
                className={`message${isMine ? ' right' : ' left'}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>

                <PictureOrSpacer/>
                <div className={`content-x${isMine ? ' right' : ''}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>
                    {!attachPrevious && (<span className={isMine ? 'name_user_right' : 'name_user_left'}>{getAuthorName()}</span>)}
                    <div className={`${getBubbleClass()}${getBubble()}${attachPrevious ? ' attach-previous' : ''}${attachNext ? ' attach-next' : ''}`}>
                        {message.replies && (<Replies/>)}
                        <Content/>
                    </div>
                    <Details side={isMine ? 'right' : 'left'} message={message}/>
                </div>
            </div>
            {totalMessageSeen > 0 && <MessageSeen key={`MessageSeen_${message._id}`}/>}
        </div>

    );
};

export default Message;
