import React, {useState} from "react";
import { SketchPicker } from 'react-color'
import "./ColorPicker.sass";

const ColorPicker = ({colorActive, setColorActive}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(true);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = (data) => {
        setColorActive(data.hex);
    };

    return (
        <div>
            <div className="swatch" onClick={ handleClick }>
            <div className="color" style={{background: colorActive}}/></div>
            { displayColorPicker ? <div className="popover">
            <div className="cover" onClick={ handleClose }/>
                <SketchPicker color={colorActive} onChange={ handleChange } />
            </div> : null }
        </div>  
    )
}

export default ColorPicker;
