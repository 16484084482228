import React, {useState, useEffect, useRef} from 'react';
import {useDispatch} from "react-redux";
import "./SearchBar.sass";
import {FiSearch} from 'react-icons/fi';
import {useGlobal} from "reactn";
import {Image} from "react-bootstrap";
import icons from "../../../assets"
import getListTagsApi from "../../../actions/getListTags";
import {FiCheck, FiCheckSquare} from 'react-icons/fi';
import getRooms from "../../../actions/getRooms";
import Actions from "../../../constants/Actions";
import useDebounce from "./useDebounce";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from './../../../translate';

const SearchBar = ({loading, setLoading}) => {
    const { t, i18n } = useTranslation();
    const searchInput = useRef();
    const dispatch = useDispatch();
    const [search, setSearch] = useGlobal('search');
    const[showDialogTag, setShowDialogTag] = useState(false);
    const[listTag, setListTag] = useState(false);
    const setActiveTags = useGlobal('activeTags')[1];
    const setPageCurrentRoom = useGlobal('pageCurrentRoom')[1];
    const[listTagActive, setListTagActive] = useState([]);
    const socialActive = useGlobal('socialActive')[0];
    const activeChannel = useGlobal('activeChannel')[0];
    const activeMessageStatus = useGlobal('activeMessageStatus')[0];
    const[isInput, setIsInput] = useState(false);
    const debouncedSearch = useDebounce(search, 500);

    useEffect(() => {
        const fetchData = () => {
            setLoading(true);
            getRooms(search, socialActive, activeChannel, activeMessageStatus, listTagActive)
                .then(res => {
                    dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms});
                    setLoading(false);
                })
                .catch(err => console.log(err));
        }
    
        if (debouncedSearch || (isInput && debouncedSearch === '')) fetchData();

    }, [debouncedSearch]);

    useEffect(() => {
        getListTagsApi().then(res => setListTag(res.data.data)).catch(err => console.log(err));
    }, []);

    const handleClickShow = () => {
        setShowDialogTag(current => !current);
    }

    const handleClickTag = async (tagId) => {
        let data = [];
        
        if(tagId !== 'all'){
            data = listTagActive;
            !data.includes(tagId) ? data.push(tagId) : data.splice(data.indexOf(tagId), 1);
        }

        setListTagActive(data);
        setActiveTags(data);

        //Reset page
        setPageCurrentRoom(1);

        dispatch({type: Actions.SET_ACTIVE_TAG, active_tags: data})

        await getRooms(search, socialActive, activeChannel, activeMessageStatus, data)
            .then(res => dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));

        setShowDialogTag(false);
    }

    const handleChangeSearchInput = (e) => {
        setIsInput(true);
        setSearch(e.target.value)
    }

    return (
        <div className="search-bar uk-flex uk-flex-center uk-flex-middle">
            <div className="icon" onClick={() => searchInput.current.focus()}>
                <FiSearch/>
            </div>
            <div className="uk-inline search">
                <input className="uk-input uk-border-pill" placeholder={`${t('search_room')}...`} 
                ref={searchInput} 
                onChange={(e) => handleChangeSearchInput(e)}/>
            </div>
            <div className="selection-tag">
                <div className="selection-tag-icon" onClick={() => handleClickShow()}>
                    <Image width="30" src={icons.Setting}/>
                    {listTagActive.length > 0 && <div className="icon-check"><FiCheckSquare/></div>}
                </div>
                {showDialogTag && (
                    <div className="select-tag-lists text-center">
                        <div className="wrapper">
                            <div className="item" onClick={() => handleClickTag('all')}>
                                <div className="color" style={{ background: '#fff' }}></div>
                                <div className="tag-name">{t('all')}</div>
                                {listTagActive.length === 0 && <div className="icon-check"><FiCheck/></div>}
                            </div>
                            {listTag.length > 0 && (
                                listTag.map(tag => (
                                    <div key={tag._id} className="item" onClick={() => handleClickTag(tag._id)}>
                                        <div className="color" style={{ background: tag.color }}></div>
                                        <div className="tag-name">{tag.name}</div>
                                        {(listTagActive && listTagActive.includes(tag._id)) && <div className="icon-check"><FiCheck/></div>}
                                    </div>))
                            )}
                        </div>
                        <div className="btn btn-primary" onClick={() => handleClickShow()}>{t('hide')}</div>
                    </div>
                )}
                
            </div>
        </div>
    );
}

export default SearchBar;
