import Config from "../config";
import React from "react";
import {useGlobal} from "reactn";

const Picture = ({user = {}, room}) => {

    let brand = useGlobal('brand')[0];

    let firstName = user.firstName || "User";
    let lastName = user.lastName || "Name";
    if (room && room.shieldedID){
        return <img className="picture" src={`${Config.url || ''}/api/images/${room?.shieldedID}/256/${brand}`} alt="Avatar 1"/>;        
    }
    else
        return <div className="img">{firstName.substr(0,1).toUpperCase()}{lastName.substr(0,1).toUpperCase()}</div>;
};

export default Picture;
