import ClipLoader from "react-spinners/ClipLoader";

const Loading = ({loading}) => {

    return (
        <div className="loading-common">
            <ClipLoader
                size={50}
                color={"#0067ac"}
                loading={loading}
            />
        </div>
    );
};

export default Loading;
