import axios from "axios";
import Config from "../config";

const addTagUser = (data) => {
    const {user_id, tag_ids} = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/tags/user-add",
        data: {user_id, tag_ids}
    });
};

export default addTagUser;
