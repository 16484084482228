import {useState, React, useEffect, useRef, useContext} from "react";
import {useSelector} from "react-redux";
import { useGlobal } from "reactn";
import Slider from "react-slick";
import './ButtonDirect.sass';
import {FiCheck, FiCheckCircle, FiLoader, FiPlay} from 'react-icons/fi';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import updateJourneyApi from "../../../actions/updateJourney";
import { AppContext } from "../../../context/AppContext";
import SweetAlert from "react-bootstrap-sweetalert";

const PipelineSlide = () => {
    const nodeRef = useRef([]);
    const customer = useSelector(state => state.io.customer);
    const [allowEditIds, setAllowEditIds] = useState([]);
    const [journeyIdActive, setJourneyIdActive] = useState([]);
    const [showPipeline, setShowPipeline] = useGlobal('showPipeline');
    const { showAlertAction, setIsLoading, i18n } = useContext(AppContext);
    const [showAlert, setShowAlert] = useState(null);

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        variableWidth: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    useEffect(() => {
        if(customer && showPipeline){
            if(customer.journey_tracking){
                let slidersTemp = customer.journey_tracking.filter(e => e.check);
                let journeyIds = pluck(slidersTemp, 'journey_id');
                let activeId = [...journeyIds].pop();
                setJourneyIdActive(activeId);
            }

            //Allow Edit
            if(customer.journey_edit){
                let journeyAllowEditIds = pluck(customer.journey_edit, 'journey_id');
                setAllowEditIds(journeyAllowEditIds);
            }

            setShowPipeline(true);
        }
        else{
            setShowPipeline(false);
        }
    }, [customer]);

    const pluck = (arr, key) => arr.map(i => i[key]);

    const getColorClass = (slider) => {
        let listClass = '';

        if(slider.check && slider.journey_id !== journeyIdActive){
            listClass += 'color-pass';
        }

        if(slider.journey_id === journeyIdActive){
            listClass += ' color-active';
        }
        else{
            listClass += ' color-deactive';
        }

        if(!isAllowEdit(slider.journey_id)){
            listClass += ' disable';
        }

        return listClass;
    }

    const getIcon = (slider) => {
        if(isAllowEdit(slider.journey_id)){
            return <FiPlay/>;
        }

        if(slider.check && slider.journey_id !== journeyIdActive){
            return <FiCheck/>;
        }

        if(slider.journey_id === journeyIdActive){
            return <FiCheckCircle/>;
        }
        else{
            return <FiLoader/>;
        }

        // if(!isAllowEdit(slider.journey_id)){
        //     listClass += ' disable';
        // }

        // return listClass;
    }

    const isAllowEdit = (journey_id) => {
        return allowEditIds && journeyIdActive !== journey_id && allowEditIds.includes(journey_id);
    }

    const handleUpdateJourney = (slider) => {
        if(!isAllowEdit(slider.journey_id)){
            return;
        }

        setIsLoading(true);
        updateJourneyApi({
            customer_lead_id: customer.customer_lead_id, 
            pipeline_id: slider.pipeline_id, 
            journey_old: customer.journey_code, 
            journey_new: slider.journey_code
        })
        .then(async(res) => {
            if(res.data.error === 0){
                // for await (let item of nodeRef.current) {
                //     item.classList.remove('color-active')
                // }

                // nodeRef.current[index].classList.remove('color-deactive');
                // nodeRef.current[index].classList.add('color-active');
                // showAlertAction('success', res.data.message);
                window.location.reload();
            }
            else{
                showAlertAction('warning', res.data.message);
            }
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            console.log('updateJourneyApi', err)
        });
    }

    // Hiển thị confirm
    const showConfirmAction = (slider) => {
        if(!isAllowEdit(slider.journey_id)){
            return;
        }

        const title = '';
        const message = `${i18n.t('confirm_update_pipeline')} "${slider.journey_name}"?`;
        
        setShowAlert(<SweetAlert 
            warning
            showCancel
            confirmBtnText={i18n.t('confirm')} 
            cancelBtnText={i18n.t('cancel')} 
            confirmBtnBsStyle="danger"
            title={title}
            btnSize="sm" 
            onConfirm={() => handleUpdateJourney(slider)} 
            onCancel={() => hideAlertAction()}>
                {message}
            </SweetAlert>
        )
    }

    const hideAlertAction = () => {
        setShowAlert(null);
    }

    return (
        <>
        {showAlert}
        {(showPipeline && customer && customer.journey_tracking) && (
            <div className="pipeline-wrap">
                <div className="title mb-2"><span className="text-bold fs-14">Pipeline: {customer.pipeline_name}</span></div>
                <div className={`pipeline-slide`}>
                    <Slider {...settings}>
                        {customer.journey_tracking.length && (
                            customer.journey_tracking.map((slider, index) => (
                                <div key={slider.journey_id} ref={(ele) => {nodeRef.current[index] = ele}}
                                    className={`${getColorClass(slider)} btn btn-sm btn-info btn-arrow-right btn-pipeline`}
                                    onClick={() => showConfirmAction(slider)}>
                                    {getIcon(slider)} <span>{slider.journey_name}</span> 
                                    {isAllowEdit(slider.journey_id) && (<span className="circle"></span>)}
                                </div>
                            ))
                        )}
                    </Slider>
                </div>
            </div>
        )}
        </>
    );
};

export default PipelineSlide;
