import axios from "axios";
import Config from "../config";

const removeTagUser = (data) => {
    const {tag_id, user_id} = data;

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/tags/remove",
        data: {tag_id, user_id}
    });
};

export default removeTagUser;
