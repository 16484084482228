import axios from "axios";
import Config from "../config";

const getListNote = (data) => {
    const { room_id, offset = 0, limit = 20 } = data;
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/v2/notes",
        data: {room_id, offset, limit}
    });
};

export default getListNote;
