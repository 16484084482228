import axios from "axios";
import setAuthToken from "./setAuthToken";
import Config from "../config";

const searchOrder = (data) => {
    const {keyword, page = 1} = data;
    
    setAuthToken(Config.epointsToken);

    return axios({
        method: "post",
        url: (Config.url || '') + "/api/orders/lists",
        data: {search: keyword, page}
    });
};

export default searchOrder;
