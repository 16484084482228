import axios from "axios";
import Config from "../config";

const getListTags = () => {
    return axios({
        method: "get",
        url: (Config.url || '') + "/api/tags/lists"
    });
};

export default getListTags;
