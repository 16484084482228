import AddCustomer from "./add-customer.png";
import AddCustomerPotential from "./add-customer-2.png";
import AddTag from "./add-tag.png";
import AddOrder from "./add-order.png";
import AddTask from "./add-task.png";
import Calendar from "./calendar.png";
import Link from "./link-icon.png";
import DeLink from "./delink-icon.png";
import Code from "./code-icon.png";
import Address from "./address-icon.png";
import Phone from "./phone-icon.png";
import Email from "./email-icon.png";
import Sales from "./sales-icon.png";
import Facebook from "./facebook.png";
import Zalo from "./zalo.png";
import Home from "./home.png";
import Customer from "./kh-icon.png";
import CpoCustomer from "./khtn-icon.png";
import Send from "./send.png";
import Setting from "./settings.png";
import LeadIcon from "./lead-icon.png";
import SocialLink from "./social-link.png";
import AddNotes from "./add-notes.png";
import AddRemind from "./add-remind.png";
import Member from "./icon-member.png";
import Silver from "./icon-silver.png";
import Gold from "./icon-gold.png";
import Diamond from "./icon-diamond.png";
import ZaloSendRating from "./zalo-rating.png";
import ZaloSendPromotion from "./zalo-promotion.png";
import PageInbox from "./page-inbox.svg";
import PageComment from "./page-comment.svg";

const images = {
    AddCustomer,
    AddCustomerPotential,
    AddTag,
    AddOrder,
    AddTask,
    Calendar,
    Link,
    DeLink,
    Code,
    Address,
    Phone,
    Email,
    Sales,
    Customer,
    CpoCustomer,
    Facebook,
    Zalo,
    Home,
    Send,
    Setting,
    LeadIcon,
    SocialLink,
    AddNotes,
    Member,
    Silver,
    Gold,
    Diamond,
    ZaloSendRating,
    ZaloSendPromotion,
    AddRemind,
    PageInbox,
    PageComment,
}

export default images