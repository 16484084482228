import axios from "axios";
import Config from "../config";

const getChannels = (source) => {
    return axios({
        method: "post",
        url: (Config.url || '') + "/api/channels/list",
        data: {source, subscribed: 1}
    });
};

export default getChannels;
